import { b as _baseIsEqual } from '../common/_baseIsEqual-b0ef5bfa.js';
import '../common/_Uint8Array-38fc1018.js';
import '../common/_MapCache-e5a0fcd9.js';
import '../common/_Map-789e2d23.js';
import '../common/isObjectLike-496ccf44.js';
import '../common/_commonjsHelpers-37fa8da4.js';
import '../common/isObject-ab5f8392.js';
import '../common/isArguments-1c726b7b.js';
import '../common/isArray-89a6a4d9.js';
import '../common/isArrayLike-14d775b9.js';
import '../common/_getAllKeys-febfddde.js';
import '../common/_arrayPush-ca285057.js';
import '../common/_getTag-0bbe87f2.js';

/**
 * Performs a deep comparison between two values to determine if they are
 * equivalent.
 *
 * **Note:** This method supports comparing arrays, array buffers, booleans,
 * date objects, error objects, maps, numbers, `Object` objects, regexes,
 * sets, strings, symbols, and typed arrays. `Object` objects are compared
 * by their own, not inherited, enumerable properties. Functions and DOM
 * nodes are compared by strict equality, i.e. `===`.
 *
 * @static
 * @memberOf _
 * @since 0.1.0
 * @category Lang
 * @param {*} value The value to compare.
 * @param {*} other The other value to compare.
 * @returns {boolean} Returns `true` if the values are equivalent, else `false`.
 * @example
 *
 * var object = { 'a': 1 };
 * var other = { 'a': 1 };
 *
 * _.isEqual(object, other);
 * // => true
 *
 * object === other;
 * // => false
 */


function isEqual(value, other) {
  return _baseIsEqual(value, other);
}

var isEqual_1 = isEqual;

export { isEqual_1 as default };
